<template>
    <div class="error-page">
        <Card class="card">
            <template #title>
                <h1>{{ $t('SSOCallbackError.error')}}</h1>
            </template>
            <template #content>
                <p>{{ $t('SSOCallbackError.' + props.errorText)}}</p>
                <Divider align="center" type="solid" />
                <Button class="standard-button" :label="$t('SSOCallbackError.back')" @click="$router.go(-1)" />

            </template>
        </Card>
    </div>
</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';
import Divider from 'primevue/divider'

import { defineProps } from 'vue';

const props = defineProps({
    errorText: String,
});



</script>

<style scoped>
.card {
    flex-grow: 1;
    max-width: 750px;
}

.error-page {
    display: flex;
    justify-content: center;
}

.standard-button {
    background-color: var(--sec-dark-purple);
    color: white;
}

.standard-button:disabled {
    background-color: var(--sec-light-purple);
    color: white;
}

.standard-button:hover {
    background-color: var(--main-dark-purple);
    color: white;
}

</style>