<template>
    <form class="action-form" @submit.prevent="handleFormSubmission">


        <div class="form-field">
            <h4 class="form-title">{{ $t('EditWarningForm.consequence') }}:</h4>
            <textarea v-model="warningForm.consequence"></textarea>
            <p v-if="errorType === 'emptyFields' && warningForm.consequence.length < 1" class="error">{{
        $t('EditWarningForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditWarningForm.answer') }}:</h4>
            <textarea v-model="warningForm.answer"></textarea>
            <p v-if="errorType === 'emptyFields' && warningForm.answer.length < 1" class="error">{{
        $t('EditWarningForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditWarningForm.risk-level') }}</h4>
            <Dropdown v-model="warningForm.risk_color" :options="colors" optionLabel="label" optionValue="value"
                :placeholder="$t('EditWarningForm.select-risk-level')">
                
                </Dropdown>
            <p v-if="errorType === 'emptyFields' && !warningForm.risk_color" class="error">{{
        $t('EditWarningForm.empty-fields') }}</p>
        </div>

        <div class="buttons-field">
            <Button :label="$t('EditWarningForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text
                style="margin-right: 10px;" />
            <Button :label="$t('EditWarningForm.save')" type="submit" icon="pi pi-check" autofocus />
        </div>
    </form>


</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

import actions from '@/assets/JSON/actions.json';
import axiosInstance from '@/services/interceptor';
import { formatDate } from '@/helpers/helpers';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const { t } = i18n.global;

const props = defineProps({
    warning: Object
})

const emit = defineEmits(['closeForm', 'warningEdited'])

const errorType = ref(null);

const colors = [
    { value: 'red', label: t("EditWarningForm.high"), level: "High" },
    { value: 'orange', label: t("EditWarningForm.medium"), level: "Medium" },
    { value: 'green', label: t("EditWarningForm.low"), level: "Low" },
    { value: 'black', label: t("EditWarningForm.nvt"), level: "NVT" },
]

const warningForm = ref({
    "id": props.warning.id || null,
    "dossier": props.warning.dossier || null,
    "name": props.warning.name || null,
    "type": props.warning.type || null,
    "answer": props.warning.answer || null,
    "consequence": props.warning.consequence || null,
    "risk_level": props.warning.risk_level || null,
    "risk_color": props.warning.risk_color || null
})

const originalWarning = ref({ ...props.warning })

async function handleFormSubmission() {
    // if the originalWarning is the same as the warningForm, then there were no changes, so we can close the form
    // if the originalWarning is different from the warningForm, then the user has made changes. Therefore, we need to check if the form is valid
    // if the form is valid, we can submit the form
    // if the form is not valid, we need to show an error message
    if (originalWarning.value.consequence === warningForm.value.consequence &&
        originalWarning.value.answer === warningForm.value.answer &&
        originalWarning.value.risk_color === warningForm.value.risk_color
    ) {
        emit('closeForm')
    } else {
        if (checkIfFormIsValid()) {
            // format the risk level
            await formatRiskLevel()
            // submit the form, put the warningForm to the backend
            await putWarning()

        } else {
            // show an error message
            showError()
        }
    }
}

function checkIfFormIsValid() {
    // check if the form is valid
    devLog('checking if form is valid')
    // if the form fields aren't empty, not null, not undefined, and it is a string with more than 1 letter, then the form is valid,
    // if that is not the case, then the form is not valid and the errorType will be emptyFields
    if (warningForm.value.consequence && warningForm.value.answer && warningForm.value.risk_color) {
        if (warningForm.value.consequence.length > 1 && warningForm.value.answer.length > 1) {
            return true;
        } else {
            showError('emptyFields');
            return false;
        }
    } else {
        showError('emptyFields');
        return false;
    }
}

async function putWarning() {
    devLog('putting warning', warningForm.value)
    // the .changed property of warningForm has to be set to true
    warningForm.value.changed = true;
    // put the warningForm to the backend
    axiosInstance.put(`warnings/${warningForm.value.id}/`, warningForm.value)
        .then((response) => {
            emit('warningEdited', response.data)
            emit('closeForm')
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
}

function showError(type) {
    // show an error message
    errorType.value = type;
}

async function formatRiskLevel() {
    // format the risk level
    warningForm.value.risk_level = colors.find(color => color.value === warningForm.value.risk_color).level
}



</script>

<style scoped>
/* FORM */
.action-form,
.action-form-empty,
.action-form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
    margin-top: 1rem;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}
</style>